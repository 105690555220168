<template>
  <div>
    <span v-if="user_status == 'true'">
      <CRow>
        <CCol class="col-md-10 px-3"></CCol>
      <CCol  class="col-md-2 filter">
      <CSelect
        required
        :value.sync="filter"
        :options="filteropt"
      />
      </CCol>
       </CRow>
    <!-- <CRow class="col-md-12" v-if="user == 'user'">
      <CCol class="col-12 col-md-4">
         <CCard class="col-md-12 p-2">
           <CRow class="col-md-12">
             <CCol class="p-2 ml-4">
               <CRow>
                 <strong class="col-md-12 p-2"><span class="ul_text">PL</span>AN</strong>
               </CRow>
               <CRow class="mt-2">
                 <span class="col-md-12 text-muted p-2"><strong>{{plan_details.plan_name}}</strong></span>
               </CRow>
             </CCol>
             <CCol>
               <img title="Plan" width="90"
                src="img/plan.png"
              />
             </CCol>
           </CRow>
        </CCard>
      </CCol>
      <CCol class="col-12 col-md-4">
        <CCard class="col-md-12 p-2">
           <CRow class="col-md-12">
             <CCol class="p-2 ml-4">
               <CRow>
                 <strong class="col-md-12 p-2"><span class="ul_text">CL</span>ICK</strong>
               </CRow>
               <CRow class="mt-2 col-md-12 text-muted p-2">
                 <span v-if="plan_details.receive_clicks > plan_details.total_clicks ">Limit exceeded</span>
                 <span v-else><strong>{{plan_details.receive_clicks}}/{{plan_details.total_clicks}}</strong> </span>
               </CRow>
             </CCol>
             <CCol>
               <img title="Click" width="90"
                src="img/click.png"
              />
             </CCol>
           </CRow>
        </CCard>
      </CCol>
      <CCol class="col-12 col-md-4">
        <CCard class="col-md-12 p-2">
           <CRow class="col-md-12">
             <CCol class="p-2 ml-4">
               <CRow>
                 <strong class="col-md-12 p-2"><span class="ul_text">TY</span>PE</strong>
               </CRow>
               <CRow class="mt-2 p-2">
                 <span class="text-muted"><strong>{{plan_details.type}}</strong></span>
               </CRow>
             </CCol>
             <CCol>
               <img title="Type" width="90"
                src="img/type.png"
              />
             </CCol>
           </CRow>
        </CCard>
      </CCol>
    </CRow> -->
   <CCardGroup columns class="card-columns cols-1">
      <CCard>
        <CCardHeader
          >Overall Click
          <div class="card-header-actions">
            <small class="text-muted"
              >Blocked Click :- {{ this.click_risk.blockedClick }}</small
            >
          </div>
        </CCardHeader>
        <CChartDoughnut
          :datasets="overallclickrisk"
          :labels="click_risk_label"
          v-if="isactive_clickrisk"
        />
      </CCard>
      <CCard>
        <CCardHeader
          >Overall Conversion
          <div class="card-header-actions">
            <small class="text-muted"
              >Non reported conversions :-
              {{ this.conversion_risk.nonReportedConv }}</small
            >
          </div>
        </CCardHeader>
        <CChartPie
          :datasets="overallconversionrisk"
          :labels="conv_risk_label"
          v-if="isactive_convrisk"
        />
      </CCard>
      <CCard>
        <CCardHeader
          >Overall Revenue
          <div class="card-header-actions">
            <small class="text-muted"
              >Non reported revenue :-
              {{ this.conversion_risk.totalConversionR }}</small
            >
          </div>
        </CCardHeader>
        <CChartPie
          :datasets="overallrevrisk"
          :labels="revenue_label"
          v-if="isactive_revrisk"
        />
      </CCard>
    </CCardGroup>
    <CRow v-if="user != 'csp'">
      <CCol :md="12">
        <CCard class="mb-4">
          <CCardBody>
            <CRow>
              <CCol :sm="4">
                <h4 id="traffic" class="card-title mb-0">Activity Statistics</h4>
                <!-- <div class="small text-medium-emphasis">January 2021</div> -->
              </CCol>
              <CCol :sm="4" class="d-none d-md-block">
                <!-- <CButton color="primary" class="float-end">
                  <CIcon name="cil-cloud-download" class="text-white" />
                </CButton> -->
                <CButtonGroup
                  class="float-end me-3"
                  role="group"
                  aria-label="Basic outlined example"
                >
                  <CButton color="info" variant="outline" @click="filter='Monthly'" :pressed="filter=='Monthly'">30 Days</CButton>
                  <CButton color="info" variant="outline" @click="filter='Weekly'" :pressed="filter=='Weekly'">7 Days </CButton>
                  <CButton color="info" variant="outline" @click="filter='Daily'" :pressed="filter=='Daily'">24 Hours</CButton>
                </CButtonGroup>
              </CCol>
              <CCol :sm="4">
                <CButtonGroup class="float-end me-3 float-md-right" role="group" aria-label="Basic outlined example">
                  <CButton color="info" variant="outline" @click="LineChartModify('All')" :pressed="showAll">All</CButton>
                  <CButton color="info" variant="outline" @click="LineChartModify('Normal')" :pressed="showNormal">Normal</CButton>
                  <CButton color="info" variant="outline" @click="LineChartModify('Suspecious')" :pressed="showSuspecious">Suspecious</CButton>
                  <CButton color="info" variant="outline" @click="LineChartModify('High')" :pressed="showHigh">High</CButton>
                </CButtonGroup>
              </CCol>
            </CRow>
            <CRow>
              <!-- <MainChartExample
                style="height: 300px; max-height: 300px; margin-top: 40px; width:100%;"
              /> -->
              <CChartLine
                  style="height: 300px; max-height: 300px; margin-top: 40px; width:100%;"
                  :datasets="defaultDatasets"
                  :options="defaultOptions"
                  :labels="activity_statics_label"
                />
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CRow :xs="{ cols: 1 }" :md="{ cols: 5 }" class="text-center">
              <CCol class="mb-sm-2 mb-0">
                <div class="text-medium-emphasis">Normal</div>
                <strong>{{ this.statics_counts.normal_counts }} Users (
                        {{
                            this.statics_counts.total_counts !== 0
                                ? (this.statics_counts.normal_counts / this.statics_counts.total_counts * 100).toFixed(2) + '%'
                                : '0%'
                        }})
                  <!-- 78.706 Views (60%) -->
                </strong>
                <CProgress
                  class="mt-2"
                  color="success"
                  thin
                  :precision="1"
                  :value="this.statics_counts.total_counts !== 0
                          ? (this.statics_counts.normal_counts / this.statics_counts.total_counts * 100)
                          : 0"
                />
              </CCol>
              <CCol class="mb-sm-2 mb-0 d-md-down-none">
                <div class="text-medium-emphasis">Suspecious</div>
                <strong>
                    {{ this.statics_counts.suspecious_counts }} Users (
                    {{
                        this.statics_counts.suspecious_counts !== 0
                            ? (this.statics_counts.suspecious_counts / this.statics_counts.total_counts * 100).toFixed(2) + '%'
                            : '0%'
                    }})
                </strong>
                <CProgress
                  class="mt-2"
                  color="warning"
                  thin
                  :precision="1"
                  :value="this.statics_counts.suspecious_counts !== 0
                            ? (this.statics_counts.suspecious_counts / this.statics_counts.total_counts * 100)
                            : 0"
                />
              </CCol>
              <CCol class="mb-sm-2 mb-0">
                <div class="text-medium-emphasis">High</div>
                <strong>
                  {{ this.statics_counts.high_counts }} Users (
                    {{
                        this.statics_counts.high_counts !== 0
                            ? (this.statics_counts.high_counts / this.statics_counts.total_counts * 100).toFixed(2) + '%'
                            : '0%'
                    }})
                </strong>
                <!-- <strong>29.703 Users (40%)</strong> -->
                <CProgress
                  class="mt-2"
                  color="danger"
                  thin
                  :precision="1"
                  :value="this.statics_counts.high_counts !== 0
                            ? (this.statics_counts.high_counts / this.statics_counts.total_counts * 100)
                            : 0"
                />
              </CCol>
              <CCol class="mb-sm-2 mb-0">
                <div class="text-medium-emphasis">Total</div>
                <strong>{{ this.statics_counts.total_counts }} Users ({{ this.statics_counts.total_counts ==0 ?0 :100 }}%)</strong>
                <!-- <strong>22.123 Users (80%)</strong> -->
                <CProgress
                  class="mt-2"
                  color="primary"
                  thin
                  :precision="1"
                  :value="this.statics_counts.total_counts == 0?0:100"
                />
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol :md="6">
        <CCard class="mb-4">
          <CCardHeader
          >Fraud Click
        </CCardHeader>
          <CCardBody>
            <CChartPie
              :datasets="fraudTypesDatasets1"
              :labels="fraudTypelabel1"
              v-if="isactive_triggerclick"
            />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol :md="6" v-if="user != 'csp'">
        <CCard class="mb-4">
          <CCardHeader
          >Device Usage
        </CCardHeader>
          <CCardBody>
            <CChartDoughnut
              :datasets="deviceUsageData"
              :labels="deviceUsagelabel"
              v-if="is_device_usage"
            />
          </CCardBody>
        </CCard>
      </CCol>
      <!-- <CCol>
        <CCard class="mb-4">
          <CCardHeader
          >Fraud Conversion
        </CCardHeader>
          <CCardBody>
            <CChartPie
              :datasets="fraudTypesDatasets2"
              :labels="fraudTypelabel2"
              v-if="isactive_triggerconversion"
            />
          </CCardBody>
        </CCard>
      </CCol> -->
      
    
    <!-- Language and device Usage -->
      <CCol :md="6" v-if="user != 'csp'">
        <CCard class="mb-4">
          <CCardHeader
          >Language Usage
        </CCardHeader>
          <CCardBody>
            <CChartBar
              :datasets="languageUsageData"
              :labels="languageUsagelabel"
            />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol :md="6" v-if="user != 'csp'">
        <CCard class="mb-4">
          <CCardHeader
          >Top clean services
        </CCardHeader>
          <CCardBody>
            <CChartBar
              :datasets="topCleanTraficData"
              :labels="customizeLabels(topCleanTraficLabel)"
              :options="clean_traffic_options"
            />
          </CCardBody>
        </CCard>
      </CCol>
    <!-- Top 10 clean services --> 
      <CCol :md="6" v-if="user != 'csp'">
        <CCard class="mb-4">
          <CCardHeader
          >Top fraud services
        </CCardHeader>
          <CCardBody>
            <CChartBar
              :datasets="topFraudTraficData"
              :labels="customizeLabels(topFraudTraficLabel)"
              :options="fraud_traffic_options"
            />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol :md="6">
        <CCard>
          <CCardHeader>Click Triggers </CCardHeader>
          <CChartBar
            :datasets="clickTrigersClick"
            :labels="fraudTypelabel1"
            v-if="isactive_triggerclick"
          />
      </CCard>
      </CCol>
    </CRow>
    <!-- <CCardGroup columns class="card-columns cols-2">
      <CCard>
        <CCardHeader>Click Triggers </CCardHeader>
        <CChartBar
          :datasets="clickTrigersConversion"
          :labels="fraudTypelabel2"
          v-if="isactive_triggerconversion"
        />
      </CCard>
    </CCardGroup> -->
    </span>
    <span v-else>
      <verify-account></verify-account>
      <!-- <CCard class="col-md-12">
         <h4 style="color:red;">Your Account is not activated</h4>
        <label><a href="#/pages/verify_account">click here</a> to verify</label>
      </CCard> -->
    </span>
    <!-- User Guide -->
    <CModal
      title="Flow"
      :show.sync="user_guide"
      size="lg"
      :centered="true"
      scrollable
      color="dark"
    >
    <center>
    <CImg src="img/introducer.jpeg" class="img-fluid" style="max-height: 500px;"></CImg>
    </center>
    <template #footer>
      <CButton @click="user_guide = false" color="danger">Close</CButton>
      <!-- <CButton @click="darkModal = false" color="success">Accept</CButton> -->
    </template>
    </CModal>
  </div>
</template>

<script>
import { CChartDoughnut } from "@coreui/vue-chartjs";
import { CChartPie } from "@coreui/vue-chartjs";
import { CChartBar } from "@coreui/vue-chartjs";
import { CChartLine } from '@coreui/vue-chartjs';
import DashboardService from "./../services/dashboardController";
import LoginService from './../services/loginControlService';
import VerifyAccount from './pages/VerifyAccount.vue';
import MainChartExample from '../views/charts/MainChartExample';
export default {
  name: "Dashboard",
  components: {
    CChartDoughnut,
    CChartPie,
    CChartBar,
    VerifyAccount,
    MainChartExample,
    CChartLine
  },
  props: {
    small: Boolean,
    // isactive_clickrisk: {
    //   type: Boolean,
    //   required: false,
    //   default: false,
    // },
    // isactive_convrisk: {
    //   type: Boolean,
    //   required: false,
    //   default: false,
    // },
    // isactive_triggerclick: {
    //   type: Boolean,
    //   required: false,
    //   default: false,
    // },
    // isactive_triggerconversion: {
    //   type: Boolean,
    //   required: false,
    //   default: false,
    // },
  },
  watch: {
    isactive_clickrisk: function() {
      console.log("isactive click risk change call");
    },
    isactive_convrisk: function() {
      console.log("isactive conrisk change call");
    },
    isactive_triggerclick: function() {
      console.log("isactive triggerclick change call");
    },
    isactive_triggerconversion: function() {
      console.log("isactive triggerconversion change call");
    },
    is_device_usage: function() {
      console.log("is_device_usage change call");
    },
    filter: function() {
      this.filterData();
    },
  },
  computed: {
    fraudTypesDatasets1 () {
      return [
        {
          backgroundColor: ['#5DC7CA','#B4A1E3','#60AFF5','#99B73D','#F6B977','#D47B7F','#74BDCB','#E4D106'],
          data: [0, 0, 0,0]
        }
      ]
    },
    fraudTypesDatasets2 () {
      return [
        {
          backgroundColor: ['#5DC7CA','#B4A1E3','#60AFF5','#99B73D','#F6B977','#D47B7F','#74BDCB','#E4D106'],
          data: [0, 0, 0, 0]
        }
      ]
    },
    topCleanTraficData() {
      return [
        {
          label: "Top 10 clean services",
          backgroundColor: "#6BA5F2",
          data: [],
        },
      ];
    },
    deviceUsageData() {
      return [
        {
          label: "Device Usage",
          // backgroundColor: "#257BB6",
          // backgroundColor: ["#89A54E","#4572A7","#AA4643"],
          backgroundColor: ["#4BA2A5","#B4A1E3","#60AFF5"],
          data: [0,0,0,0,0],
        },
      ];
    },
    topFraudTraficData() {
      return [
        {
          label: "Top 10 fraud services",
          backgroundColor: "#93706C",
          data: [],
        },
      ];
    },
    languageUsageData(){
      return [
        {
          label: "Language Usage",
          backgroundColor: "#D968AE",
          data: [0,0,0,0,0],
        },
      ];
    },
    overallclickrisk() {
      return [
        {
          backgroundColor: ["#E46651","#00D8FF","#41B883"],
          data: [0, 0, 0],
        },
      ];
    },
    overallconversionrisk() {
      return [
        {
          backgroundColor: ["#E46651", "#00D8FF","#41B883"],
          data: [0, 0, 0],
        },
      ];
    },
    overallrevrisk() {
      return [
        {
          backgroundColor: ["#E46651", "#00D8FF","#41B883"],
          data: [0, 0, 0],
        },
      ];
    },
    clickTrigersClick() {
      return [
        {
          label: "Click",
          backgroundColor: "#ff8080",
          data: [0, 0, 0, 0, 0],
        },
      ];
    },
    clickTrigersConversion() {
      return [
        {
          label: "Conversion",
          backgroundColor: "#99b3ff",
          data: [0, 0, 0, 0, 0],
        },
      ];
    },
    defaultDatasets () {
      const datasets = [];
      if (this.showNormal || this.showAll) {
        datasets.push({
          label: 'Normal',
          backgroundColor: 'transparent',
          borderColor: '#64B95D',
          pointHoverBackgroundColor: '#64B95D',
          borderWidth: 2,
          data: this.linechart_data.normal_counts,
        });
      }
      if (this.showSuspecious || this.showAll) {
        datasets.push({
          label: 'Suspecious',
          backgroundColor: 'transparent',
          borderColor: '#F6B122',
          pointHoverBackgroundColor: '#F6B122',
          borderWidth: 2,
          data: this.linechart_data.suspecious_counts,
        });
      }
      if (this.showHigh || this.showAll) {
        datasets.push({
          label: 'High',
          backgroundColor: 'transparent',
          borderColor: '#E55353',
          pointHoverBackgroundColor: '#E55353',
          borderWidth: 2,
          data: this.linechart_data.high_counts,
        });
      }
      return datasets;
    },
    defaultOptions () {
      return {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 10,
              stepSize: Math.ceil(200 / 20),
              // max: 250
            },
            gridLines: {
              display: true
            }
          }]
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        }
      }
    }
  },
  data() {
    return {
      showNormal: false,
      showSuspecious: false,
      showHigh: false,
      showAll: true,
      linechart_data:{normal_counts:[],suspecious_counts:[],high_counts:[]},
      statics_counts:{normal_counts:0,suspecious_counts:0,high_counts:0,total_counts:0},
      activity_statics_label:[],
      topCleanTraffic:[],
      topFraudTraffic:[],
      clickTrigerLabels:{harmfullBRAPPOS:'Suspicious Pattern',requestTempring:'Request Tempering',headerInjection:'Header Injection',suspeciusActivity:'Suspicious Activity',iframeTraffic:'Iframe Traffic',proxyTraffic:'Poxy Traffic',adultKeyword:'Adult Keyword',nonPlayStoreApk:'Non Play Store Apk'},
      convTriggerLabels:{convHarm: 'Suspicious Pattern',convReqTemp: 'Request Tempering',convheadInj: 'Header Injection',convSusAct: 'Suspicious Activity',convIframe: 'Iframe Traffic',convProxy: 'Poxy Traffic',convAdult: 'Adult Keyword',convNonPlay: 'Non Play Store Apk'},
      fraudTypelabel1:[],
      fraudTypelabel2:[],
      is_device_usage:false,
      isactive_clickrisk:false,
      isactive_convrisk:false,
      isactive_triggerclick:false,
      isactive_triggerconversion:false,
      plan_details:{plan_name:'',type:"",total_clicks:0,receive_clicks:0},
      user_guide: false,
      filter:'Monthly',
      filteropt:[
        { value: 'Monthly', label: 'Last 30 Days' },
        { value: 'Weekly', label: 'Last 7 Days' },
        { value: 'Daily', label: 'Last 24 Hours' },
      ],
      totalRows:0,
      tableperpage:20,
      perPage:[20,50,100],
      isactive_revrisk:false,
      pageOptions: [10, 15],
      items: [],
      click_trigger: {},
      conv_trigger: {},
      click_risk_label: ["High Risk", "Suspicious", "Low Risk"],
      conv_risk_label: ["High Risk", "Suspicious", "Low Risk"],
      revenue_label: ["High Risk", "Suspicious", "Low Risk"],
      user: "",
      user_status: false,
      camp_list: [],
      offer_name: [],
      traffic_source_name: [],
      click_risk: { blockedClick: null },
      conversion_risk: {},
      barchartlabel: [
        "Suspicious Pattern",
        "Request Tempering",
        "Header Injection",
        "Suspicious Activity",
      ],
      topCleanTraficLabel: [],
      deviceUsagelabel:["Mobile","Tablet","Desktop"],
      topFraudTraficLabel: [],
      languageUsagelabel:[],
      filter_dash:{sdate:new Date(),edate:new Date()},
      isExpired:false,
      clean_traffic_options : {
        responsive : true,
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: this.showFullCleantrafficlable
          }
        }
      },
      fraud_traffic_options : {
        responsive : true,
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: this.showFullFraudtrafficlable
          }
        }
      }
    };
  },
  async mounted() {
    if(window.localStorage.getItem("user") == 'manager'){
      this.$router.push("/manager/userlist");
    }
    else{
    if (
      !window.localStorage.getItem("user") ||
      window.localStorage.getItem("user") == "null"
    ) {
      // alert("Please Enter User Name and Password");
      this.$router.push("/pages/login");
    } else {
      this.user = window.localStorage.getItem("user");
      this.user_status = window.localStorage.getItem("status");
      if(this.user_status == 'true' && window.localStorage.getItem("user") == 'user'){
        let response = await DashboardService.getplandetails();
        if(response.result){
        this.plan_details = response.message
        var today = new Date();
        var expiry_date = new Date(this.plan_details.expiry_date);
        if(today > expiry_date){
          this.isExpired = true
          // alert("Your plan is expired")
          // this.logout()
        }
        }
      }
      this.getCamplist();
      this.filterData();
      // if(window.localStorage.getItem('user_guide') == 'true'){
      // this.user_guide = window.localStorage.getItem('user_guide')
      // window.localStorage.setItem('user_guide',false)
      // }
    }}
  },
  methods: {
    showFullCleantrafficlable(tooltipItems, data){
      const foundItem = this.topCleanTraficLabel.find(item => {
      const firstFiveCharacters = item.substring(0, 15); // Get first 5 characters of the item
      return firstFiveCharacters === tooltipItems.label.substring(0, 15); // Compare with the first 5 characters of the search string
      });
      return foundItem+ ' : ' + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];
    },
    showFullFraudtrafficlable(tooltipItems, data){
      const foundItem = this.topFraudTraficLabel.find(item => {
      const firstFiveCharacters = item.substring(0, 15); // Get first 5 characters of the item
      return firstFiveCharacters === tooltipItems.label.substring(0, 15); // Compare with the first 5 characters of the search string
      });
      return foundItem+ ' : ' + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];
    },
    customizeLabels(categories) {
      return categories.map((category) =>
        category.length > 15 ? category.substring(0, 15) + "..." : category
      );
    },
    LineChartModify(dataset){
      this.showNormal = this.showSuspecious = this.showHigh = this.showAll = false;
      switch (dataset) {
      case 'Normal':
        this.showNormal = !this.showNormal;
        break;
      case 'Suspecious':
        this.showSuspecious = !this.showSuspecious;
        break;
      case 'High':
        this.showHigh = !this.showHigh;
        break;
      default:
        this.showNormal = false;
        this.showSuspecious = false;
        this.showHigh = false;
        this.showAll = true;
        break;
      }
    },
    async getclickslines(){
      let payload = {
        "sdate": this.filter_dash.sdate,
        "edate": this.filter_dash.edate,
        "granularity": this.filter.toLowerCase()};
      let response = await DashboardService.getclickslines(payload);
      if(response.result){
      this.activity_statics_label = [];
      for(const date in response.data){
        const counts = response.data[date];
        let dateObject = new Date(date)
        if(this.filter == 'Daily'){
          this.activity_statics_label.push(dateObject.toLocaleTimeString('en-GB', {hour: '2-digit' , hour12: true}))
        }else{
          this.activity_statics_label.push(dateObject.toLocaleDateString('en-GB', {day: '2-digit',month: '2-digit'}))
        }
        // also add low counts to normal counts - normal counts are low counts + normal counts
        this.statics_counts.normal_counts += parseInt(counts.normal);
        this.statics_counts.normal_counts += parseInt(counts.low);
        this.statics_counts.suspecious_counts += parseInt(counts.sus);
        this.statics_counts.high_counts += parseInt(counts.high);
        this.linechart_data.normal_counts.push(parseInt(counts.normal) + parseInt(counts.low));
        this.linechart_data.suspecious_counts.push(parseInt(counts.sus));
        this.linechart_data.high_counts.push(parseInt(counts.high));
      }
      this.statics_counts.total_counts = this.statics_counts.normal_counts + this.statics_counts.suspecious_counts + this.statics_counts.high_counts 
      }
    },
    findCampNameById(id) {
        for (const [campName, campId] of Object.entries(this.camp_list)) {
          if (campId === id) {
            return campName;
          }
        }
        return null; // Return null if ID is not found
      },
    async getCamplist(){
      let response = await DashboardService.camplist();
      if (response.result){
        this.camp_list = response.message.campaign;
        }
    },
    async getTopServices(){
      let payload = {"granularity": this.filter.toLowerCase()};
      var response = await DashboardService.getTopServices(payload);
      this.topCleanTraffic = response.data.topCleanTraffic;
      this.topCleanTraficLabel = [];
      this.topFraudTraficLabel = [];
      this.topCleanTraficData[0].data = [];
      this.topFraudTraficData[0].data = [];
      this.topCleanTraffic.forEach(el => {
        if(this.findCampNameById(el.camp_id) != null){
          this.topCleanTraficLabel.push(this.findCampNameById(el.camp_id))
          this.topCleanTraficData[0].data.push(el.accepted)
        }
      });
      this.topFraudTraffic = response.data.topFraudTraffic;
      this.topFraudTraffic.forEach(el => {
        if(this.findCampNameById(el.camp_id) != null){
          this.topFraudTraficLabel.push(this.findCampNameById(el.camp_id))
          this.topFraudTraficData[0].data.push(el.rejected)
        }
      });
    },
   async getDeviceandLanguageData(){
      try {
        this.is_device_usage = false;
        let payload = {
            "sdate": this.filter_dash.sdate,
            "edate": this.filter_dash.edate,    
            "granularity": this.filter.toLowerCase()
        };
          var response = await DashboardService.getDeviceandLanguageData(payload);
          // Extract device types excluding empty keys
          if(Object.keys(response.data.devicetype).length !== 0){
            this.deviceUsagelabel = Object.keys(response.data.devicetype).filter(el => el !== '');
            // Map device usage data
            this.deviceUsageData[0].data = this.deviceUsagelabel.map(el => response.data.devicetype[el].browse);
            this.deviceUsageData[0].backgroundColor = ["#4BA2A5","#B4A1E3","#60AFF5"];
          }
          else{
            this.deviceUsagelabel = ["No Data"];
            this.deviceUsageData[0].data = [100];
            this.deviceUsageData[0].backgroundColor = ["#e0e0d1"];
          }
          // Extract language types excluding empty keys
          if(Object.keys(response.data.languages).length !== 0){
            this.languageUsagelabel = Object.keys(response.data.languages).filter(el => el !== '');
            // Map language usage data
            this.languageUsageData[0].data = this.languageUsagelabel.map(el => response.data.languages[el].browse);
          }
          else{
            this.languageUsagelabel = ["No Data"];
            this.languageUsageData[0].data = [0];
          }
          this.is_device_usage = true;
      } catch (error) {
          console.error('Error fetching device and language data:', error);
          // Handle the error as needed
      }
    },
    filterData(){
      this.isactive_clickrisk = false;
      this.isactive_convrisk = false;
      this.isactive_revrisk = false;
      this.isactive_triggerclick = false;
      this.isactive_triggerconversion = false;
      var date = new Date();
      var expiry_date = new Date();
      this.filter_dash.edate = new Date(date.setHours(23,59))
      switch(this.filter){
        case 'Monthly':expiry_date = new Date(date.getFullYear(), date.getMonth(), date.getDate()-30)
            break;
        case 'Weekly':expiry_date = new Date(date.getFullYear(), date.getMonth(), date.getDate()-7)
          break;
        case 'Daily':expiry_date = new Date(date.setHours(23,59))
          break;
          default:expiry_date = new Date(date.getFullYear(), date.getMonth(), date.getDate()-30)
      }
      this.filter_dash.sdate  = new Date(expiry_date.setHours(0,0))
       this.dateFilter({startDate:this.filter_dash.sdate,endDate:this.filter_dash.edate});
      this.runQuery()
    },
    dateFilter(data) {
      this.filter_dash.sdate = new Date(
        data.startDate.getTime() - data.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.filter_dash.edate = new Date(
        data.endDate.getTime() - data.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      if(this.filter == 'Daily'){
      let edate = new Date()
      let sdate = new Date(edate)
        sdate = sdate.setTime(edate.getTime() - (24*60*60*1000))
        this.filter_dash.sdate = new Date(sdate).toISOString()
      }
    },
    runQuery: async function() {
      try {
        let dash_data = await DashboardService.dash(this.filter_dash);
        this.click_risk = dash_data.message.clickRisk;
        this.conversion_risk = dash_data.message.convRisk;
        this.click_risk.blockedClick = dash_data.message.clickRisk.blockedClick;
        this.click_trigger = dash_data.message.clickTrigger;
        this.conv_trigger = dash_data.message.convTrigger;
        // this.highrisk_data[0].data = [this.click_risk.hr,this.click_risk.ss,this.click_risk.lr];
        // console.log(this.highrisk_data[0])
        this.conversion_risk = dash_data.message.convRisk;
        this.statics_counts.normal_counts = this.statics_counts.suspecious_counts = this.statics_counts.high_counts = this.statics_counts.total_counts =  0;
        this.linechart_data.normal_counts = []
        this.linechart_data.suspecious_counts = []
        this.linechart_data.high_counts = [];
        if(this.user != 'csp'){
          this.getDeviceandLanguageData();
          this.getclickslines();
          this.getTopServices();
        }
          this.checkChartData();
      } catch (e) {
        console.log("Error in getting dashboard data:");
        console.log(e);
      }
    },
    checkChartData() {
      if (this.click_risk.hr == 0 && this.click_risk.ss == 0 && this.click_risk.lr == 0) {
        this.fillblanck("fillblanckClickrisk");
      } else {
        this.overallclickrisk[0].data[0] = this.click_risk.hr;
        this.overallclickrisk[0].data[1] = this.click_risk.ss;
        this.overallclickrisk[0].data[2] = this.click_risk.lr;
        this.click_risk_label = ["High Risk", "Suspicious", "Low Risk"],
        this.overallclickrisk[0].backgroundColor = ["#E46651","#00D8FF","#41B883"];
      }
      this.isactive_clickrisk = true;
      if (this.conversion_risk.hr == 0 && this.conversion_risk.ss == 0 && this.conversion_risk.lr == 0) {
        this.fillblanck("fillblanckConvrisk");
      } else {
        this.overallconversionrisk[0].data[0] = this.conversion_risk.hr;
        this.overallconversionrisk[0].data[1] = this.conversion_risk.ss;
        this.overallconversionrisk[0].data[2] = this.conversion_risk.lr;
        this.conv_risk_label = ["High Risk", "Suspicious", "Low Risk"],
        this.overallconversionrisk[0].backgroundColor = ["#E46651", "#00D8FF","#41B883"]
      }
      this.isactive_convrisk = true;
      if (this.conversion_risk.hrr == 0 &&this.conversion_risk.ssr == 0 && this.conversion_risk.lrr == 0) {
        this.fillblanck("fillblanckRevrisk");
      } else {
        this.overallrevrisk[0].data[0] = this.conversion_risk.hrr;
        this.overallrevrisk[0].data[1] = this.conversion_risk.ssr;
        this.overallrevrisk[0].data[2] = this.conversion_risk.lrr;
        this.overallrevrisk[0].backgroundColor = ["#E46651", "#00D8FF","#41B883"]
        this.revenue_label= ["High Risk", "Suspicious", "Low Risk"]
      }
      this.isactive_revrisk = true;
      this.manageClickConvChart();
    },
    manageClickConvChart(){
      var clickTriggerValues = Object.values(this.click_trigger);
      var isClickTriggerAllZeros = clickTriggerValues.every(value => value === 0);
      this.clickTrigersClick[0].data = [];
      this.fraudTypesDatasets1[0].data = [];
      this.fraudTypelabel1 = [];
        if (isClickTriggerAllZeros) {
          for(const click in this.click_trigger){
            const counts = this.click_trigger[click];
            this.clickTrigersClick[0].data[click] = this.fraudTypesDatasets1[0].data[click] = counts;
          }
          this.fillblanck("fillblankFraudTypesClick");
        } else{
          for(const click in this.click_trigger){
            const counts = this.click_trigger[click];
            this.clickTrigersClick[0].data.push(counts);
            this.fraudTypesDatasets1[0].data.push(counts);
            this.fraudTypelabel1.push(this.clickTrigerLabels[click]);
            
          }
          this.fraudTypesDatasets1[0].backgroundColor = ['#5DC7CA','#B4A1E3','#60AFF5','#99B73D','#F6B977','#D47B7F','#74BDCB','#E4D106'];
        }
      this.isactive_triggerclick = true;
      var convTriggerValues = Object.values(this.conv_trigger);
      var isconvTriggerAllZeros = convTriggerValues.every(value => value === 0);
      this.clickTrigersConversion[0].data = [];
      this.fraudTypesDatasets2[0].data = [];
      this.fraudTypelabel2 = [];
      if (isconvTriggerAllZeros) {
        for(const click in this.conv_trigger){
          const counts = this.conv_trigger[click];
          this.clickTrigersConversion[0].data[click] = this.fraudTypesDatasets2[0].data[click] = counts;
        }
        this.fillblanck("fillblankFraudTypesConv");
      } else{
        for(const click in this.conv_trigger){
          const counts = this.conv_trigger[click];
          this.clickTrigersConversion[0].data.push(counts);
          this.fraudTypesDatasets2[0].data.push(counts);
          this.fraudTypelabel2.push(this.convTriggerLabels[click]);
          
        }
        this.fraudTypesDatasets2[0].backgroundColor = ['#5DC7CA','#B4A1E3','#60AFF5','#99B73D','#F6B977','#D47B7F','#74BDCB','#E4D106'];
      }
      this.isactive_triggerconversion = true;
    },
    fillblanck(data) {
      switch (data) {
        case "fillblanckClickrisk":
          this.overallclickrisk[0].data[0] = 100;
          this.overallclickrisk[0].backgroundColor = ["#e0e0d1"];
          this.click_risk_label = ["Click Data Not Available"];
          break;
        case "fillblanckConvrisk":
          this.overallconversionrisk[0].data = [100,0,0];
          this.overallconversionrisk[0].backgroundColor = ["#e0e0d1"];
          this.conv_risk_label = ["Conv Data Not Available"];
          break;
        case "fillblanckRevrisk":
          this.overallrevrisk[0].data = [100,0,0];
          this.overallrevrisk[0].backgroundColor = ["#e0e0d1"];
          this.revenue_label = ["Revenue Data Not Available"];
          break;
        case "fillblankFraudTypesClick":
          this.fraudTypesDatasets1[0].data = [100,0,0,0];
          this.fraudTypesDatasets1[0].backgroundColor = ["#e0e0d1"];
          this.fraudTypelabel1 = ["Fraud Clicks Not Available"];
          break;
        case "fillblankFraudTypesConv":
          this.fraudTypesDatasets2[0].data = [100,0,0,0];
          this.fraudTypesDatasets2[0].backgroundColor = ["#e0e0d1"];
          this.fraudTypelabel2 = ["Fraud Conversion Not Available"];
          break;
      }
    },
    color(value) {
      let $color;
      if (value <= 25) {
        $color = "info";
      } else if (value > 25 && value <= 50) {
        $color = "success";
      } else if (value > 50 && value <= 75) {
        $color = "warning";
      } else if (value > 75 && value <= 100) {
        $color = "danger";
      }
      return $color;
    },
    async logout() {
      let response = await LoginService.logout(this.input)
          window.localStorage.setItem("user", null);
          window.localStorage.setItem("status", null);
          window.localStorage.setItem("email", null);
          location.reload();
          this.$router.push("/pages/login");
      },
  },
};
</script>

<style scoped>
.filter{
    display: block;
    margin-top: -27px;
    margin-bottom: -10px;
}
</style>
